<template>
  <div class="ticket-tipe-page py-6">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-9">
          <b-steps
            class="box"
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode"
          >
            <!-- Paso 1: Selección del tipo de boleto y cantidad de pasajeros -->
            <b-step-item step="1" label="Boletos" :clickable="isStepsClickable">
              <h1 class="title has-text-centered mt-6">Selección de Boletos</h1>
              <div class="columns is-multiline is-centered">
                <div class="column is-6 mb-6">
                  <b-field label="Tipo de Boleto">
                    <b-radio
                      v-for="ticket in ticketTypes"
                      :key="ticket.id"
                      v-model="reservation.ticket_type"
                      @input="onChange('ticket_type', ticket.name), onChange('ticketPrice', ticket.price)"
                      :native-value="ticket.name"
                    >
                      {{ capitalizeFirstLetter(ticket.name) }}
                    </b-radio>
                  </b-field>
                  <b-field label="Cantidad de Pasajeros">
                    <b-input v-model="reservation.ticket_quantity" type="number" min="1" @input="updatePassengerCount($event)"></b-input>
                  </b-field>
                  <p><strong>Fecha seleccionada:</strong> {{ formatDate(transportSchedule.departure_time) }}</p>
                  <p><strong>Hora seleccionada:</strong> {{ formatTime(transportSchedule.departure_time) }}</p>
                </div>
              </div>
            </b-step-item>

            <!-- Paso 2: Información de los pasajeros -->
            <b-step-item step="2" label="Pasajeros" :clickable="isStepsClickable">
              <h1 class="title has-text-centered mt-6">Datos de los Pasajeros</h1>
              <div class="columns is-multiline is-centered">
                <div v-for="(passenger, index) in passengers" :key="index" class="column is-12">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <h3 class="title is-5">Pasajero #{{ index + 1 }}</h3>
                    </div>
                    <b-field label="Nombre" class="column is-3">
                      <b-input v-model="passenger.name" required></b-input>
                    </b-field>
                    <b-field label="Apellidos" class="column is-3">
                      <b-input v-model="passenger.lastName" required></b-input>
                    </b-field>
                    <b-field label="Correo" class="column is-3">
                      <b-input v-model="passenger.email" type="email" required></b-input>
                    </b-field>
                    <b-field label="Teléfono" class="column is-3">
                      <b-input v-model="passenger.phone" type="tel" required></b-input>
                    </b-field>
                    <b-field label="Tipo de Pasajero" class="column is-3">
                      <b-select v-model="passenger.type" expanded required>
                        <option value="adulto">Adulto</option>
                        <option value="nino">Niño</option>
                        <option value="tercera_edad">3ra Edad</option>
                      </b-select>
                    </b-field>
                    <b-field label="País" class="column is-3">
                      <b-input v-model="passenger.country" required></b-input>
                    </b-field>
                    <b-field label="Estado" class="column is-3">
                      <b-input v-model="passenger.state" required></b-input>
                    </b-field>
                  </div>
                </div>
                <b-field label="Numero de emergencia *" class="column is-12">
                  <b-input v-model="reservation.emergencyNumber" expanded required type="tel"></b-input>
                </b-field>
              </div>
            </b-step-item>

            <!-- Paso 3: Selección de Asientos -->
            <b-step-item step="3" label="Asientos" :clickable="isStepsClickable">
              <h1 class="title has-text-centered mt-6">Selección de los Asientos</h1>
              <Seats />
            </b-step-item>

            <!-- Paso 4: Finalizar Orden -->
            <b-step-item step="4" label="Pago" :clickable="isStepsClickable">
              <h1 class="title has-text-centered mt-6">Pagar</h1>
              <!-- Aquí se integrará el componente de finalización de pago, como PayPal -->
              <PaymentConfirmation v-if="activeStep == 3" :reservation="reservation" />
            </b-step-item>

            <!-- Botones de navegación personalizados -->
            <template v-if="customNavigation" #navigation="{ previous, next }">
              <div class="buttons is-centered mt-5 box">
                <b-button outlined type="is-primary" icon-pack="fas" icon-left="backward" :disabled="previous.disabled" @click.prevent="prevStep">
                  Anterior
                </b-button>
                <b-button outlined type="is-primary" icon-pack="fas" icon-right="forward" :disabled="next.disabled" @click.prevent="nextStep">
                  Siguiente
                </b-button>
              </div>
            </template>
          </b-steps>
        </div>
        <div class="column is-3">
          <div class="order-details box">
            <p class="title is-5">Detalles</p>
            <p class="subtitle is-6 m-0"><b>Fecha:</b> {{ formatDate(transportSchedule.departure_time) }}</p>
            <p class="subtitle is-6 m-0"><b>Hora:</b> {{ formatTime(transportSchedule.departure_time) }}</p>
            <p class="subtitle is-6 m-0"><b>Tipo de transporte:</b> {{ isTrain ? 'Tren' : 'Autobus' }}</p>
            <p class="subtitle is-6 m-0"><b>Tipo de boleto:</b> {{ capitalizeFirstLetter(reservation.ticket_type) }}</p>
            <p class="subtitle is-6 m-0"><b>Cantidad:</b> {{ reservation.ticket_quantity }}</p>
            <p class="subtitle is-6 m-0"><b>Asientos:</b></p>
            <hr />
            <p class="subtitle is-6 m-0">
              <b>Subtotal: {{ formatCurrency(subtotal) }}</b>
            </p>
            <p class="subtitle is-6 m-0">
              <b>Descuentos: {{ formatCurrency(0) }}</b>
            </p>
            <hr />
            <p class="subtitle is-6 m-0">
              <b>Total: {{ formatCurrency(total) }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Swal from 'sweetalert2';

import Seats from './seatSelection.vue';
import PaymentConfirmation from './paymentConfirmation.vue';

export default {
  components: {
    Seats,
    PaymentConfirmation,
  },
  props: {
    reservationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeStep: 0,
      ticketType: 'oro',
      passengerCount: 1,
      selectedDate: '2024-08-20', // Ejemplo de fecha seleccionada
      selectedTime: '11:00 AM', // Ejemplo de hora seleccionada

      // Opciones de configuración del componente de pasos
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
    };
  },
  computed: {
    ...mapState({
      reservation: (state) => state.reservations.reservation,
      passengers: (state) => state.reservations.reservation.passengers,
      transportSchedule: (state) => state.reservations.reservation.transportSchedule,
      ticketTypes: (state) => state.reservations.reservation.ticketTypes,
      isTrain: (state) => state.ui.isTrain,
    }),
    loadIsTrain() {
      this.setLogoHeader(this.$route.path.includes('tren'));
      return this.isTrain;
    },
    discount() {
      return 0;
    },
    subtotal() {
      const subtotal = this.reservation.ticketPrice * this.reservation.ticket_quantity;
      return subtotal;
    },
    total() {
      const total = this.subtotal - this.discount;

      this.setDataReservation({ ['total']: total });
      this.setDataReservation({ ['subtotal']: this.subtotal });
      this.setDataReservation({ ['discount']: this.discount });

      return total;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.setLogoHeader(this.$route.path.includes('tren'));
    await this.loadReservationValues();
  },
  beforeDestroy() {
    this.setLogoHeader(false);
  },
  watch: {
    passengers: {
      handler(newPassengers) {
        this.updatePassengers(newPassengers);
      },
      deep: true, // Esto asegura que se observen los cambios profundos en el array y sus objetos
    },
  },
  methods: {
    ...mapActions({
      updateTicketQuantity: 'reservations/updateTicketQuantity',
      setLogoHeader: 'ui/setLogoHeader',
      fetchReservationValues: 'reservations/fetchReservationValues',
    }),
    ...mapMutations({
      setDataReservation: 'reservations/SET_DATA_RESERVATION',
      updatePassengers: 'reservations/UPDATE_PASSENGERS',
    }),
    onChange(key, value) {
      const payload = { [key]: value };
      this.setDataReservation(payload);
    },
    updatePassengerCount(value) {
      this.updateTicketQuantity(value);
    },
    nextStep() {
      window.scrollTo(0, 0);
      if (this.validateSteps()) {
        if (this.activeStep < 4) {
          this.activeStep++;
        }
      }
    },
    prevStep() {
      window.scrollTo(0, 0);
      if (this.activeStep > 0) {
        this.activeStep--;
      }
    },
    validateSteps() {
      switch (this.activeStep) {
        case 0:
          return this.validateSelectionTickets();
        case 1:
          return this.validatePassengers();
        case 2:
          return this.validateSeats();
        default:
          return true;
      }
    },
    validateSelectionTickets() {
      if (!this.reservation.ticket_type) {
        this.showAlert('Por favor selecciona un tipo de boleto para continuar.');
        return false;
      }

      if (this.reservation.ticket_quantity < 1) {
        this.showAlert('Por favor selecciona una cantidad de pasajeros mayor para continuar.');
        return false;
      }

      return true;
    },
    validatePassengers() {
      let isValid = true;

      this.passengers.forEach((passenger, index) => {
        if (
          !passenger.name ||
          !passenger.lastName ||
          !this.isValidEmail(passenger.email) ||
          !passenger.phone ||
          !passenger.type ||
          !passenger.country ||
          !passenger.state
        ) {
          isValid = false;
          this.showAlert(`Por favor completa todos los campos del pasajero #${index + 1} con un email válido.`);
          return false;
        }
      });

      if (!this.reservation.emergencyNumber) {
        isValid = false;
        this.showAlert('Por favor ingresa un número de emergencia válido.');
      }

      return isValid;
    },
    validateSeats() {
      let isValid = true;

      // Verifica que el tipo de boleto no sea 'bar'
      if (this.reservation.ticket_type !== 'bar') {
        // Compara la cantidad de boletos solicitados con la cantidad de asientos seleccionados
        if (this.reservation.ticket_quantity*1 !== this.reservation.seats.length) {
          isValid = false; // Cambia isValid a false
          this.showAlert(
            `La cantidad de boletos solicitados (${this.reservation.ticket_quantity}) debe ser igual a la cantidad de asientos seleccionados (${this.reservation.seats.length}).`,
          );
        }
      }

      return isValid;
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    isValidPhoneNumber(phoneNumber) {
      const phoneRegex = /^[0-9]{10,15}$/; // Valida números entre 10 y 15 dígitos
      return phoneRegex.test(phoneNumber);
    },
    showAlert(message) {
      Swal.fire({
        text: message,
        icon: 'info',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#29abe2',
      });
    },
    async loadReservationValues() {
      await this.updateTicketQuantity(1);
      await this.fetchReservationValues({ schedule_id: this.reservationId });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', options);
    },
    formatTime(dateString) {
      const options = { hour: '2-digit', minute: '2-digit', hour12: true };
      const time = new Date(dateString);
      return time.toLocaleTimeString('es-ES', options);
    },
    formatCurrency(value) {
      return `$${parseFloat(value).toFixed(2)} MXN`;
    },
  },
};
</script>

<style lang="scss">
.ticket-type-selection {
  .steps {
    margin-bottom: 2rem;
  }
  .step-content {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
  }
}
</style>
